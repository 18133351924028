import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

import goneHybridLogo from "../images/gone-hybrid-logo.svg";
import Pagination from "../components/pagination";

function IndexPage({ data, pageContext }) {
  const { group, index, first, last, pageCount } = pageContext; // pagination data, exposed!

  return (
    <Layout>
      <SEO keywords={[`mobile`, `angular`, `ionic`, `graphql`]} title="Home" />

      <div className="flex items-center flex-col md:flex-row mb-16 text-center md:text-left">
        <img className="w-3/4 md:w-1/2" src={goneHybridLogo} />
        <h1 className="text-3xl md:text-4xl font-bold md:ml-16 mt-12">
          Learn to build mobile apps with Angular and Ionic.
        </h1>
      </div>
      <div className="mt-8">
        <Pagination
          first={first}
          last={last}
          index={index}
          pageCount={pageCount}
        />
        {group.map((
          { node } // group contains meta about _each_ post!
        ) => (
          <div key={node.id} className="">
            <h2 className="text-2xl font-bold mb-4">
              <Link to={node.frontmatter.slug}>{node.frontmatter.title}</Link>
            </h2>
            <div className="mb-4 text-md font-thin flex flex-wrap items-center">
              {node.frontmatter.prettyDate}
              <svg
                className="inline-block ml-8"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
              >
                <path d="M2.59 13.41A1.98 1.98 0 0 1 2 12V7a5 5 0 0 1 5-5h4.99c.53 0 1.04.2 1.42.59l8 8a2 2 0 0 1 0 2.82l-8 8a2 2 0 0 1-2.82 0l-8-8zM20 12l-8-8H7a3 3 0 0 0-3 3v5l8 8 8-8zM7 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </svg>

              {/* TODO: the tags need to be links to pages where you can see all the posts associated with the tag */}

              {node.frontmatter.tags &&
                node.frontmatter.tags.length &&
                node.frontmatter.tags
                  .filter(tag => !tag.startsWith("#"))
                  .map(tag => (
                    <span className="ml-2" key={tag}>
                      {tag}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ", ", curr])}
            </div>
            <p className="mb-16 text-lg">{node.excerpt} </p>
          </div>
        ))}
        <Pagination
          first={first}
          last={last}
          index={index}
          pageCount={pageCount}
        />
      </div>
    </Layout>
  );
}

export default IndexPage;
