import { Link } from "gatsby";
import React, { useState } from "react";

function Pagination({ first, last, index, pageCount }) {
  /* with the pagination metadata, we can build in custom controls for each
   * paginated index page!
   */

  const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
  const nextUrl = (index + 1).toString();

  return (
    <nav className="text-md text-gray-600 font-thin flex mb-8">
      <span className="flex-1 text-left">
        {!first && (
          <Link to={previousUrl} className="border-none">
            ← Newer
          </Link>
        )}
      </span>
      {/* {!first && <span className="flex-1">|</span>} */}
      <span className="flex-1 text-center">
        Page {index} of {pageCount}
      </span>
      {/* {!last && <span className="flex-1">|</span>} */}

      <span className="flex-1 text-right">
        {!last && (
          <Link className="flex-1 border-none" to={nextUrl}>
            Older →
          </Link>
        )}
      </span>
    </nav>
  );
}

export default Pagination;
